import Cookies from "js-cookie";

// HOC
export const RenderABValueFromCookie = ({
  cookie,
  components,
}: {
  cookie: string | undefined;
  components: Record<string, JSX.Element>;
}) => {
  if (!cookie) return <></>;
  return components[cookie];
};

// UTILS
export const setABTestingValue = (key: string, values: string[]) => {
  if (!values.includes(Cookies.get(key) || "")) {
    Cookies.set(key, cookieRandomGenerator(values));
  }
};

export const cookieRandomGenerator = (strings: string[]): string => {
  const randomIndex = Math.floor(Math.random() * strings.length);
  return strings[randomIndex];
};

export function parseCookie(cookieHeader: string | null): {
  [key: string]: string;
} {
  const cookies: { [key: string]: string } = {};

  if (cookieHeader) {
    const cookiePairs = cookieHeader.split(";");

    for (const cookiePair of cookiePairs) {
      const [name, value] = cookiePair.trim().split("=");
      cookies[name] = value;
    }
  }

  return cookies;
}

export const COOKIE_BANNER_KEY = "cookieBanner";
export const COOKIE_BANNER_VALUES = ["cookieBannerA", "cookieBannerB"];
